import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Buy from "../components/buy";
import VariantSelector from "../components/variantSelector";
import { useQueryParam, NumberParam } from "use-query-params";

function Shop() {
  const { allShopifyCollection } = useStaticQuery(graphql`
    query DefaultShopQuery {
      allShopifyCollection {
        edges {
          node {
            title
            products {
              title
              id
              images {
                originalSrc
              }
              variants {
                title
                price
                shopifyId
              }
            }
          }
        }
      }
    }
  `);

  const [num] = useQueryParam("preview", NumberParam);
  if (!num) {
    return <div />;
  }

  return (
    <div>
      {allShopifyCollection.edges
        .filter(({ node }) => node.products.length)
        .map(({ node }) => (
          <div key={node.title} className="my-10">
            <h4 className="inline-block pb-2 mb-4 border-b-4 border-black ">
              {node.title}
            </h4>
            <div className="grid md:grid-cols-3 gap-4">
              {node.products.map((product, key) => (
                <div key={key}>
                  <div className="my-2">
                    <img src={product.images[0].originalSrc} />
                  </div>
                  <h4>{product.title}</h4>

                  {product.variants.length === 1 ? (
                    <div>
                      <p className="my-4 py-2 text-base">
                        £{product.variants[0].price}
                      </p>
                      <Buy product={product.variants[0]} />
                    </div>
                  ) : (
                    <VariantSelector variants={product.variants} />
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
}

export default Shop;
