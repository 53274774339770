/* eslint-disable react/prop-types */
import React, { useState, useRef } from "react";

export const Faqs = ({ title, body }) => {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
  }

  return (
    <section className="shadow w-full">
      <article className="border-b overflow-hidden" onClick={toggleAccordion}>
        <div
          className={
            setActive === "active"
              ? "border-l-2 border-primary transform transition-all duration-200"
              : "border-l-2 border-transparent transform transition-all duration-200"
          }
        >
          <header className="flex justify-between items-center p-5 pl-8 pr-8 cursor-pointer select-none">
            <span className="text-grey-darkest  text-xl pr-4 font-bold">
              {title}
            </span>
            <div
              className={
                setActive === "active"
                  ? "rounded-full border border-primary w-7 h-7 flex items-center justify-center text-primary rotate-180 transform transition-all duration-200"
                  : "rounded-full border border-grey w-7 h-7 flex items-center justify-center transform transition-all duration-200"
              }
            >
              <svg
                aria-hidden="true"
                className=""
                data-reactid="266"
                fill="none"
                height="24"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            </div>
          </header>
          <div ref={content} style={{ maxHeight: `${setHeight}` }}>
            <div className="pl-8 pr-8 pb-5 text-grey-darkest">
              <p>{body}</p>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};
