import React from "react";
import PropTypes from "prop-types";

import { graphql, Link } from "gatsby";
import GoogleMapReact from "google-map-react";

import { FaMapMarkerAlt } from "react-icons/fa";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Faqs } from "../components/accordion";
import Shop from "../components/shop";
function IndexPage({ data, pageContext }) {
  const { parent, children, siblings } = pageContext;
  const { frontmatter, html } = data.markdownRemark;
  return (
    <Layout
      fixedHeader={frontmatter.image ? true : false}
      parent={parent}
      title={frontmatter.title}
    >
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title={frontmatter.title}
      />

      <PageTemplate
        title={frontmatter.title}
        html={html}
        image={frontmatter.image}
        intro={frontmatter.intro}
        faqs={frontmatter.faqs}
        parent={parent}
        childPages={children}
        siblings={siblings}
      />
    </Layout>
  );
}

export const PageTemplate = ({
  image,
  title,
  intro,
  html,
  parent,
  childPages,
  siblings,
  faqs,
}) => {
  let subNavPages = !parent ? childPages : siblings;

  subNavPages.sort((a, b) =>
    a.node.frontmatter.order > b.node.frontmatter.order ? 1 : -1
  );

  return (
    <>
      <section
        className="relative flex items-center justify-center w-full h-screen py-16 bg-black"
        style={{ maxHeight: "600px" }}
      >
        <img
          alt="Festivals"
          className="absolute inset-0 object-cover w-full h-full opacity-75"
          src={image}
        />
        <div className="container relative z-10 px-2 text-center text-white">
          <h2 className="font-extrabold text-white title pattern-text ">
            {title}
          </h2>
          <h4 className="text-4xl font-semibold text-white normal-case">
            {intro}
          </h4>
        </div>
      </section>
      {html && (
        <section className="w-full py-12" id="content">
          <div className="container">
            <div className="flex flex-col-reverse md:flex-row">
              {subNavPages.length > 0 && (
                <div className="w-64">
                  <h4>
                    <Link
                      to={parent ? parent.node.fields.slug + "#content" : null}
                      className="inline-block pb-2 mb-2 border-b-4 border-black "
                    >
                      {parent ? parent.node.frontmatter.title : title}
                    </Link>
                  </h4>
                  {subNavPages && (
                    <ul className="font-semibold text-gray-600 -ml-4 pr-4">
                      {subNavPages.map((p) => (
                        <li className="mb-2" key={p.node.fields.slug}>
                          <Link
                            to={p.node.fields.slug + "#content"}
                            activeClassName="text-white bg-gray-600 rounded-lg"
                            className="hover:text-primary py-2 px-4 block"
                          >
                            {p.node.frontmatter.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
              <div className="max-w-3xl mx-auto pb-12">
                {title === "Location" && (
                  <>
                    <div
                      className="w-full h-screen mb-6"
                      style={{ maxHeight: "420px" }}
                    >
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: "AIzaSyC-pek62fWn7mFLTVktr2LKWEX6aEJLdWg",
                        }}
                        defaultCenter={[56.216429, -3.4596157]}
                        defaultZoom={12}
                      >
                        <div
                          className="marker"
                          lat="56.216429"
                          lng="-3.4596157"
                        >
                          <FaMapMarkerAlt
                            size={48}
                            style={{ transform: "translateY(-100%)" }}
                          />
                        </div>
                      </GoogleMapReact>
                    </div>
                    <a
                      href="https://www.google.co.uk/maps/dir//Fly+Scotland,+Balado+Airfield,+Kinross+KY13+0RF/@56.2165645,-3.5272934,12z/data=!4m9!4m8!1m0!1m5!1m1!1s0x48862d8e79ffd49f:0x61b0fd1d833edd7a!2m2!1d-3.457427!2d56.216429!3e0"
                      className="font-impact  text-white  py-2 px-5  inline-block bg-primary uppercase font-bold rounded shadow-md border-0 mb-6"
                    >
                      Get directions of Google Maps »
                    </a>
                  </>
                )}

                <article
                  className=" prose prose-xl"
                  dangerouslySetInnerHTML={{ __html: html }}
                />
                {title === "FAQs" && (
                  <section className="w-full my-6">
                    {faqs.map((f) => (
                      <Faqs key={f.title} title={f.title} body={f.body} />
                    ))}
                  </section>
                )}
                {title === "Shop" && (
                  <section className="w-full my-6">
                    <Shop />
                  </section>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

PageTemplate.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  intro: PropTypes.string,
  html: PropTypes.string,
  parent: PropTypes.object,
  childPages: PropTypes.array,
  siblings: PropTypes.array,
  faqs: PropTypes.object,
};

IndexPage.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      html: PropTypes.string,
    }),
  }),
};

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image
        intro
        faqs {
          body
          title
        }
        widgets {
          title
          text
          link
          image
        }
      }
      html
    }
  }
`;

export default IndexPage;
