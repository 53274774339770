/* eslint-disable react/prop-types */
import React from "react";
// import ShopifyBuy from "@shopify/buy-button-js";
import Client from "shopify-buy";

// eslint-disable-next-line react/prop-types
const Buy = ({ product }) => {
  const client = Client.buildClient({
    domain: process.env.SHOP_NAME + ".myshopify.com",
    storefrontAccessToken: process.env.SHOPIFY_ACCESS_TOKEN,
  });

  //   console.log(client);

  // console.log("dd");

  const addItemToCart = (product) => {
    console.log(product);
    client.checkout.create().then((checkout) => {
      // Do something with the checkout
      client.checkout
        .addLineItems(checkout.id, {
          variantId: product.shopifyId,
          quantity: 1,
        })
        .then((checkout) => {
          // Do something with the updated checkout
          window.open(checkout.webUrl);
        });
    });
  };

  return (
    <div>
      <button
        className="font-impact text-white border-white border-2 py-2 px-5 text-lg inline-block bg-primary uppercase font-bold"
        onClick={() => addItemToCart(product)}
      >
        Add to cart
      </button>
    </div>
  );
};

export default Buy;
