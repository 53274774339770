/* eslint-disable react/prop-types */
import React from "react";
// import ShopifyBuy from "@shopify/buy-button-js";
import StoreContext from "../context/StoreContext";
import ThemeContext from "../context/ThemeContext";
// eslint-disable-next-line react/prop-types
const VariantSelector = ({ variants }) => {
  const [variant, setVariant] = React.useState(variants[0]);
  const { addVariantToCart } = React.useContext(StoreContext);
  const { setVisible } = React.useContext(ThemeContext);

  return (
    <div>
      <select
        onChange={(e) => setVariant(e)}
        className="w-full text-sm border p-2 bg-white my-4 rounded"
      >
        {variants.map((v) => (
          <option value={v.shopifyId} key={v.shopifyId}>
            {v.title} - £{v.price}
          </option>
        ))}
      </select>
      <button
        className="font-impact text-white border-white border-2 py-2 px-5 text-base inline-block bg-primary uppercase font-bold"
        onClick={() => {
          addVariantToCart(variant.shopifyId, 1), setVisible(true);
        }}
      >
        Add to cart
      </button>
    </div>
  );
};

export default VariantSelector;
